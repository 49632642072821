import React from 'react';

// Modal
import ModalDetalleCaja from './ModalDetalleCaja/ModalDetalleCaja';

// router
import { withRouter } from 'react-router-dom';
import { faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';

// services
import { deleteCaja } from '../../Services/cajaService';

// Sweet alert
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ItemCajaReporte = ({ item, setIsLoad, history }) => {

    const estado = (estado) => {
        if (estado === "ABIERTA") return <p className="text-success">ABIERTA</p>;
        else return <p className="text-primary">CERRADA</p>;
    }

    const deleteCajaSubmit = (caja) => {
        Swal.fire({
            title: 'Estas seguro de eliminar?',
            text: "Se eliminara una caja!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                deleteCaja(caja.id)
                    .then(res => {
                        Swal.fire(
                            'Eliminado!',
                            'La caja fue eliminada.',
                            'success'
                        );
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        });
                        console.log(error.response);
                    })
            }
        })
    }

    return (
        <tr>
            <th scope="row">{item.id}</th>
            <td>{item.puntoVenta.nombre}</td>
            <td>{item.createdAt.split('T')[0]} / {item.createdAt.split('T')[1].replace('-03:00', '')}</td>
            <td>{(item.fechaCierre) ?
                `${item.fechaCierre.split('T')[0]} / ${item.fechaCierre.split('T')[1].replace('-03:00', '')}`
                : '-'
            }</td>
            <td>${parseFloat(item.montoInicial).toFixed(2)}</td>
            <td>${(item.montoFinal != null) ?
                `${parseFloat(item.montoFinal).toFixed(2)}` : `${"0.0"}`
            }
            </td>
            <td>
                {estado(item.estado)}
            </td>
            <td>
                {
                    item.estado === 'ABIERTA' ?
                        <button
                            className="btn text-danger"
                            onClick={() => history.push("/edit-caja", { puntoVenta: item.puntoVenta })}
                        >
                            <FontAwesomeIcon icon={faWindowClose} /> Cerrar
                        </button> : null
                }
                <ModalDetalleCaja caja={item} />
                <button
                    className="btn text-primary"
                    onClick={ () => deleteCajaSubmit(item)}
                >
                    <FontAwesomeIcon icon={faTrash} /> Eliminar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemCajaReporte);