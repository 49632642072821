import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// services
import { recoverUsuarioDeleted } from '../../Services/usuarioService';

// Sweet alert
import Swal from 'sweetalert2';

// router
import { withRouter } from 'react-router-dom';
//import Usuario from './Usuario';

const ItemUsuarioEliminado = ({ usuario, setIsLoad }) => {

    const recoverUsuarioSubmit= (usuario) => {
        Swal.fire({
            title: 'Estas seguro de recuperarlo?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Recuperar!',
            cancelButtonText: 'Cancelar'
        }).then((result) => {
            if (result.value) {
                // consulta a la api para eliminar
                recoverUsuarioDeleted(usuario.id)
                    .then(res => {
                        Swal.fire(
                            'Recuperado!',
                            'El usuario fue recuperado.',
                            'success'
                        );
                        setIsLoad(true);
                    })
                    .catch(error => {
                        Swal.fire({
                            type: 'error',
                            title: 'Error',
                            text: 'Hubo un error vuelve a intentar!'
                        });
                        console.log(error.response);
                    })
            }
        })
    }


    return (
        <tr>
            <th scope="row">{usuario.id}</th>
            <td>{usuario.usuario.nombre}</td>
            <td>{usuario.usuario.apellido}</td>
            <td>{usuario.usuario.correo}</td>
            <td>{usuario.username}</td>
            <td>{usuario.roles[0].nombre}</td>
            <td>
                <button className="btn text-warning"
                        onClick={() => { recoverUsuarioSubmit(usuario)}}>
                    <FontAwesomeIcon icon={faTrash}/> Recuperar
                </button>
            </td>
        </tr>
    );
}

export default withRouter(ItemUsuarioEliminado);