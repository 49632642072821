import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import { editByIdTarjetas } from '../../Services/tarjetaService';
import { saveError } from "../../Services/errorService";

import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard, faPlus } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2';
import Header from "../Header/Header";

// formik
import { useFormik } from 'formik';
import * as Yup from 'yup';

const EditTarjeta = ({history, location}) => {

    const tarjeta = location.state.tarjeta;

    const formik = useFormik({
        initialValues:{
            nombre: tarjeta.nombre,
        },
        validationSchema:Yup.object({
            nombre: Yup.string().required("Obligatorio."),
        }),
        onSubmit: values => editTarjetaSubmit(values)
    })

    const editTarjetaSubmit = (values) => {

        const tarjetaToUpdate = {
            ...values,
            id: tarjeta.id
        }
        editByIdTarjetas(tarjetaToUpdate)
            .then(res => {
                Swal.fire({
                    text: 'Tarjeta editada correctamente',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1000
                });
                history.goBack();
            })
            .catch(async error => {
                const errorData = {
                    "mensaje": "Error al intentar modificar la tarjeta",
                    "detalle": error.message,
                    "entidad": "TARJETA",
                    "tipo": "WEBAPP",
                    "severidad": "HIGH",
                    "reportado": "APPLICATION"
            };

            await saveError(errorData);
                Swal.fire({
                    text: 'Error al modificar la tarjeta, verifique los datos',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 2000
                });
                this.setState({ error: true });
            });
    }

    return (
        <Fragment>
            <Header />
    <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
            <BotonBack />
            <Subtitulo
                icon={faCreditCard}
                title={'Editar tarjeta'} />
        </div>

        <div className="bg-gris p-4">
            <form onSubmit={formik.handleSubmit}>
                <div className="form-row">

                    <div className="form-group col-md-6">
                        <label htmlFor="inputNombre">Nombre</label>
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faCreditCard} />
                            </span>
                            <input type="text"
                                id="inputNombre"
                                name="nombre"
                                className="form-control"
                                value={formik.values.nombre}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                        {formik.touched.nombre && formik.errors.nombre ?
                            <small className="form-text text-danger">
                                {formik.errors.nombre}
                            </small>
                            : null}
                    </div>
                </div>

                <div className="text-center">
                    <button type="submit" className="btn btn-outline-success">
                        <FontAwesomeIcon icon={faPlus} /> Editar tarjeta
                    </button>
                </div>
            </form>
        </div>
    </div>
</Fragment>

    );
}

export default withRouter(EditTarjeta);