import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

// routes
import { withRouter } from "react-router-dom";

// Sweet alert
import Swal from "sweetalert2";

// services
import { deleteByIdMarcas } from "../../Services/marcaService";

const ItemMarca = ({ marca, history, setIsLoad }) => {
  const btnEliminarMarca = (id) => {
    Swal.fire({
      title: "Estas seguro de eliminar?",
      text: "Una marca eliminada no se puede recuperar!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        // consulta a la api para eliminar
        deleteByIdMarcas(id)
          .then((res) => {
            Swal.fire("Eliminado!", "La marca fue eliminada.", "success");
            setIsLoad(true);
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              title: "Error",
              text: "Hubo un error vuelve a intentar!",
            });
            console.log(error.response);
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{marca.id}</th>
      <td>{marca.nombre}</td>
      <td>% {marca.ganancia || 0}</td>
      <td>
        <button
          className="btn text-danger"
          onClick={() => {
            history.push(`/edit-marca/${marca.id}`, { marca: marca });
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> Editar
        </button>
        <button
          className="btn text-primary"
          onClick={() => btnEliminarMarca(marca.id)}
        >
          <FontAwesomeIcon icon={faTrash} /> Eliminar
        </button>
      </td>
    </tr>
  );
};

export default withRouter(ItemMarca);
