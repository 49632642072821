import React, { Fragment, useEffect, useState } from 'react';

// icons
import { faBarcode, faSearch } from '@fortawesome/free-solid-svg-icons'

// styles
import './Articulos.css'

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import ItemArticuloEliminado from './ItemArticuloEliminado';

// services
import { getAllArticulos } from '../../Services/articuloService';

// route
import { withRouter } from 'react-router-dom';
import Header from '../Header/Header';

// alert swal
import Swal from 'sweetalert2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ArticulosEliminados({ history }) {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [articulos, setArticulos] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    // pagination
    const [page, setPage] = useState(0);
    const [totalRegister, setTotalRegister] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (rol !== 'ROLE_ADMIN_MAGENTA') history.goBack();
        if (isLoad) {
            getAllArticulos(false, page, query)
                .then(res => {
                    setArticulos(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch(error => console.log(error.response));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);

    const nextPage = () => {
        if (totalPage === 0) {
            Swal.fire({
                toast: true,
                position: "top",
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        if (page === totalPage - 1) {
            Swal.fire({
                toast: true,
                position: "top",
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        getAllArticulos(false, page + 1, query)
            .then(res => {
                setPage(page + 1);
                setArticulos(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));


    }

    const previousPage = () => {
        if (page === 0) {
            Swal.fire({
                toast: true,
                position: "top",
                title: 'No hay mas páginas',
                type: 'info',
                showConfirmButton: false,
                timer: 1000
            })
            return;
        }
        getAllArticulos(false, page - 1, query)
            .then(res => {
                setPage(page - 1)
                setArticulos(res.data.content);
                setTotalRegister(res.data.totalElements);
                setTotalPage(res.data.totalPages);
                setIsLoad(false);
            })
            .catch(err => console.log('error'));

    }

    const handleBuscador = (e) => {
        setQuery(e.target.value);
        if (e.key === "Enter") {
            // verificar si la query isEmpty hacer otra consulta
            getAllArticulos(false, 0, e.target.value)
                .then((res) => {
                    setPage(0);
                    setArticulos(res.data.content);
                    setTotalRegister(res.data.totalElements);
                    setTotalPage(res.data.totalPages);
                    setIsLoad(false);
                })
                .catch((err) => console.log("error"));
        }
    };

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBarcode}
                        title={'Articulos Eliminados'} />
                </div>

                <div className="d-flex bd-highlight">

                    <div className="p-2 flex-grow-1 bd-highlight">
                        <div className="input-group-prepend input-group-lg">

                            <span className="input-group-text" id="inputGroupPrepend">
                                <FontAwesomeIcon icon={faSearch} />
                            </span>

                            <input type="text"
                                className="form-control form-lg"
                                id="inputNombre"
                                name="precio"
                                placeholder="Buscar"
                                onKeyPress={(e) => handleBuscador(e)}
                            />

                        </div>
                    </div>
                </div>

                <div className="bg-gris p-3">

                    <div className="d-flex flex-row bd-highlight">
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => previousPage()} aria-label="Previous">
                                <span aria-hidden="true">&laquo; Anterior</span>
                            </button>
                        </div>
                        <div className="p-2 bd-highlight mt-1">
                            Registros: {totalRegister} | Pagina actual: {page + 1} | Total páginas : {totalPage}
                        </div>
                        <div className="p-2 bd-highlight">
                            <button className="btn btn-outline-info"
                                onClick={() => nextPage()} aria-label="Next">
                                <span aria-hidden="true">Siguiente &raquo;</span>
                            </button>
                        </div>
                    </div>


                    <table className="table table-striped mt-3 text-center">
                        <thead>
                            <tr>
                                <th scope="col">Codigo</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Pesable</th>
                                <th scope="col">Balanza</th>
                                <th scope="col">Compuesto</th>
                                <th scope="col">Costo</th>
                                <th scope="col">Precio Final</th>
                                <th scope="col">Ganancia</th>
                                <th scope="col">Familia</th>
                                <th scope="col">Marca</th>
                                <th scope="col">Unidad Medida</th>
                                <th scope="col">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {articulos.map((articulo) => (
                                <ItemArticuloEliminado
                                    key={parseInt(articulo.id, 10)}
                                    setIsLoad={setIsLoad}
                                    articulo={articulo} />
                            ))}
                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
}

export default withRouter(ArticulosEliminados);