import axios from 'axios';
import { url_node } from './global';

export const getPdf = async () => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const res = await axios.post(`${url_node}/pdf`, { headers });
    return res;
}

export const getPdfComprobanteVenta = async (venta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const resource = {
        resource: venta
    }
    const res = await axios.post(`${url_node}/venta`, resource, { headers });
    return res.data;
}

export const getPDFStockActual = async (articulos) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const resource = {
        resource: articulos
    }
    const res = await axios.post(`${url_node}/stock`, resource, { headers });
    return res.data;
}

export const getPdfReporteHistorialPagosDeCompras = async (pagos) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: {
            pagos
        }
    }
    const res = await axios.post(`${url_node}/historialPagosDeCompras`, data, { headers });
    return res.data;
}

export const getPdfPresupuesto = async (presupuesto) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: presupuesto
    }

    const res = await axios.post(`${url_node}/presupuesto`, data, { headers });
    return res.data;

}

export const getPdfHistorialPagosConCC = async (pagos) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: {
            pagos
        }
    }

    const res = await axios.post(`${url_node}/historialPagosConCC`, data, { headers });
    return res.data;

}

export const sendPresupuestoPorMail = async ({ presupuesto }, emailData) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: presupuesto,
        emailData: emailData
    }
    const res = await axios.post(`${url_node}/presupuesto`, data, { headers });
    return res;
}

export const articulosExcel = async (articulos, emailData) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: articulos,
        emailData: emailData
    }
    const res = await axios.post(`${url_node}/articulos`, data, { headers });
    return res;
}

export const getExcelReporteVenta = async (ventasArticulos, ventasTotales, ventasMPagos, emailData) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: {
            ventasArticulos,
            ventasTotales,
            ventasMPagos
        },
        emailData
    }
    const res = await axios.post(`${url_node}/ventas/reportes`, data, { headers });
    return res;
}

export const getExcelReporteCompra = async (compras, total, emailData) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: {
            compras,
            total
        },
        emailData
    }
    const res = await axios.post(`${url_node}/compras/reportes`, data, { headers });
    return res;
}

export const getExcelReporteCaja = async (cajas, total, emailData) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": token
    }
    const data = {
        resource: {
            cajas,
            total
        },
        emailData
    }
    const res = await axios.post(`${url_node}/cajas/reportes`, data, { headers });
    return res;
}