import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faCashRegister, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// service
import { signOut } from '../../Services/usuarioService';

// link
import { Link } from 'react-router-dom';

const Header = ({ history }) => {


    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let user = token.user_name;
    let role = token.authorities[0];


    const logOut = () => {
        signOut();
        history.push('/');
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <a className="navbar-brand" href="/"><b>{process.env.REACT_APP_BUSINESS_NAME}</b></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarColor03">
                {(role === 'ROLE_ADMIN_MAGENTA' || role === 'ROLE_SUCURSAL') ?
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <Link className="nav-link" to="/home">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/presupuestos">Presupuestos</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/marcas">Marcas</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/empleados">Empleados</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/departamentos">Departamento</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/familias">Familia</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/punto-ventas">Punto Ventas</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/dato-fiscal">Dato Fiscal</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/cajas">Cajas</Link>
                        </li>
                    </ul>
                    :
                    null
                }

                {(role === 'ROLE_CAJERO') ?
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <Link className="nav-link" to="/home">Home</Link>
                        </li>
                    </ul>
                    :
                    null

                }


                <div className="form-inline my-2 my-lg-0">
                    {(role === 'ROLE_ADMIN') ?
                        <div>
                            <button className="btn btn-outline-success mr-2"
                                onClick={() => history.push('/cajas')}>
                                <FontAwesomeIcon icon={faCashRegister} /> Cajas
                            </button>
                        </div>
                        : null
                    }
                    <div className="dropdown">
                        <button className="btn btn-outline-info dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FontAwesomeIcon icon={faUser} />  {(user).toUpperCase()}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                            {/* <button className="dropdown-item" >Configuraciones</button> */}
                            {/* <button className="dropdown-item" onClick={() => { history.push('/preferencias') }}> <FontAwesomeIcon icon={faUserCog} /> Preferencias</button> */}
                            <button className="dropdown-item" onClick={() => { logOut() }}> <FontAwesomeIcon icon={faSignOutAlt} /> Cerrar Sesion</button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default withRouter(Header);