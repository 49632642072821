import axios from 'axios';
import { url } from './global';

export const getAllCajas = async (activo, page, sucursal, nombre, size = 50) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/v2/cajas?${nombre?`nombre=${nombre}&`:""}sucursal=${sucursal}&activo=${activo}&page=${page}&size=${size}`, { headers });
    return res;
}

export const save = async (caja) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.post(`${url}/v2/cajas`, caja, { headers });
    return res;
}

export const addItem = async (caja) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/v2/cajas/${caja.id}/add-item`, caja, { headers });
    return res;
}

export const cierreCaja = async (caja) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/v2/cajas/${caja.id}/cierre`, caja, { headers });
    return res;
}

// GET CAJA ACTIVA
export const getCajaActivaByPtoVenta = async (puntoVenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/v2/cajas/punto-venta/${puntoVenta}/activa`, { headers });
    return res;
}

// GET CAJA WITH ITEMS FINALES
export const getCajaItemFinalByPtoVenta = async (puntoVenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/cajas/itemscierre/${puntoVenta}`, { headers });
    return res;
}

// DELETE ITEM CAJA
export const deleteItemCaja = async (caja) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/v2/cajas/${caja.id}/delete-item`, caja, { headers });
    return res;
}

// REPORTES CAJAS
export const getCajasReportes = async (sucursal, fechaDesde, fechaHasta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/v2/reportes/cajas/fecha-inicio/${fechaDesde}/fecha-fin/${fechaHasta}?sucursal=${sucursal}`, { headers });
    return res;
}

export const deleteCaja = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.delete(`${url}/v2/cajas/${id}`, { headers });
    return res;
}

export const recoverCaja = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/v2/cajas/${id}/recover`, {}, { headers });
    return res;
}

