import React from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

// routes
import { withRouter } from 'react-router-dom';

//services
import { recoverPresupuesto } from '../../Services/presupuestoService';

// Sweet alert
import Swal from 'sweetalert2';


const ItemPresupuestoEliminado = ({ presupuesto, setIsLoad }) => {

  const recover = async (presupuesto) => {
    Swal.fire({
      title: 'Estas seguro de recuperarlo?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Recuperar!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        recoverPresupuesto(presupuesto.id)
          .then(res => {
            Swal.fire(
              'Recuperado!',
              'El presupuesto de la sucursal fue recuperado.',
              'success'
            );
            setIsLoad(true);
          })
          .catch(error => {
            Swal.fire({
              type: 'error',
              title: 'Error',
              text: 'Hubo un error vuelve a intentar!'
            });
            console.log(error.response);
          })
      }
    })
  }

  return (
    <tr>
      <th scope="row">{presupuesto.id}</th>
      <td>${(presupuesto.total).toFixed(2)}</td>
      <td>{(presupuesto.createdAt).split('T')[0]}</td>
      <td>{(presupuesto.fechaVencimiento).split('T')[0]}</td>
      <td>{presupuesto.sucursal.nombre}</td>
      <td>{presupuesto.cliente.id.persona.apellido}, {presupuesto.cliente.id.persona.nombre}</td>
      <td>
        <button className="btn text-warning"
          onClick={() => { recover(presupuesto) }}>
          <FontAwesomeIcon icon={faTrash} /> Recuperar
        </button>
      </td>
    </tr>
  );
}

export default withRouter(ItemPresupuestoEliminado);