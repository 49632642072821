import React, { Fragment, useState, useEffect } from 'react';

// router
import { withRouter } from 'react-router-dom';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import Header from "../Header/Header";

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faEdit, faShoppingCart, faDollarSign } from '@fortawesome/free-solid-svg-icons'

// services
import { saveStockSucursal } from '../../Services/stockSucursalService';
import { saveError } from "../../Services/errorService";
import { getAllSucursales } from '../../Services/sucursalService';

// Sweer Alert
import Swal from 'sweetalert2';

// Modals
import ModalArticulo from './ModalArticulo/ModalArticulo'

//Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';


const AddStockSucursal = ({ history }) => {

    let token = JSON.parse(atob(sessionStorage.getItem('token').split('.')[1]));
    let rol = token.authorities[0];

    const [articulo, setArticulo] = useState({ nombre: '' });
    const [sucursales, setSucursales] = useState([]);

    const formik = useFormik({
        initialValues: {
            cantidad: 0,
            precioFinal: 0.0,
            sucursal: token.sucursal
        },
        validationSchema: Yup.object({
            cantidad: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo."),
            precioFinal: Yup.number().required("Obligatorio.").min(0, "El numero debe ser positivo."),
            sucursal: Yup.object().required("Obligatorio.")
        }),
        onSubmit: values => saveStockSucursalSubmit(values)
    })

    useEffect(() => {
        getAllSucursales(true, 0, "", 1000)
          .then(res => {
            setSucursales(res.data.content);
          })
          .catch((err) => console.log("error get sucursal"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    const saveStockSucursalSubmit = (values) => {

        let stockSucursal = {
            articuloId: articulo.id,
            sucursalId: values.sucursal.id,
            cantidad: values.cantidad,
            precioFinal: values.precioFinal
        }

        saveStockSucursal(stockSucursal)
            .then(res => {
                Swal.fire({
                    position: "top",
                    toast: true,
                    title: 'Articulo agregado a sucursal.',
                    type: 'success',
                    showConfirmButton: false,
                    timer: 1500
                })
                history.goBack();
            })
            .catch(async error => {
                const errorData = {
                    "mensaje": "Error al intentar añadir un articulo en sucursal",
                    "detalle": error.response.data.message,
                    "entidad": "STOCK_SUCURSAL",
                    "tipo": "WEBAPP",
                    "severidad": "HIGH",
                    "reportado": "APPLICATION"
                };

                await saveError(errorData);
                Swal.fire({
                    title: error.response.data.message || 'Error al añadir el articulo a la sucursal.',
                    type: 'error',
                    showConfirmButton: false,
                    timer: 3000
                })
            });

    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBuilding}
                        title={'Stock Sucursales'} />
                </div>

                <div className="bg-gris p-4">

                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-column">
                            <div className="form-group col-md-6" style={{ marginRight: "auto", marginLeft: "auto", display: "block" }}>
                                <label htmlFor="inputArticuloNombre">Articulo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </span>
                                    <input type="text"
                                        id="inputArticuloNombre"
                                        name="nombre"
                                        className="form-control"
                                        disabled
                                        value={articulo.nombre.trim()}
                                    />
                                    <ModalArticulo
                                        setCodigoArticulo={setArticulo} />
                                </div>
                            </div>

                            <div className="form-group col-md-6" style={{ marginRight: "auto", marginLeft: "auto", display: "block" }}>
                                <label htmlFor="inputArticuloCantidad">Cantidad</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faShoppingCart} />
                                    </span>
                                    <input type="number"
                                        step="any"
                                        id="inputArticuloCantidad"
                                        name="cantidad"
                                        className="form-control"
                                        value={formik.values.cantidad}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.cantidad && formik.errors.cantidad ?
                                    <small className="form-text text-primary font-weight-bold">
                                        {formik.errors.cantidad}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6" style={{ marginRight: "auto", marginLeft: "auto", display: "block" }}>
                                <label htmlFor="inputArticuloPrecioFinal">Precio de sucursal</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input type="number"
                                        step="any"
                                        id="inputArticuloPrecioFinal"
                                        name="precioFinal"
                                        className="form-control"
                                        placeholder={articulo.costo ? "Costo: " + articulo.costo : ''}
                                        value={formik.values.precioFinal}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.precioFinal && formik.errors.precioFinal ?
                                    <small className="form-text text-primary font-weight-bold">
                                        {formik.errors.precioFinal}
                                    </small>
                                    : null}
                            </div>

                            <div className="form-group col-md-6" style={{ marginRight: "auto", marginLeft: "auto", display: "block" }}>
                                <label htmlFor="inputDepartamento">Sucursales</label>
                                <div>
                                    <Select 
                                        placeholder={"Seleccionar un sucursal"}
                                        options={sucursales}
                                        defaultValue={formik.values.sucursal}
                                        onChange={value => formik.setFieldValue('sucursal', value)}
                                        getOptionValue={(values) => values.id}
                                        getOptionLabel={(values) => values.nombre}
                                        isDisabled={rol === 'ROLE_ADMIN_MAGENTA' ? false : true }
                                        >
                                    </Select>
                                </div>
                                {formik.touched.sucursal && formik.errors.sucursal ?
                                    <small className="form-text text-danger">
                                        {formik.errors.sucursal}
                                    </small>
                                    : null}
                            </div>

                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faEdit} /> Añadir Stock</button>
                        </div>
                    </form>
                </div>
            </div>

        </Fragment>
    );
};

export default withRouter(AddStockSucursal);