import React, { Fragment } from "react";

// components
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";

// redux
import { useDispatch } from "react-redux";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";

// router
import { withRouter } from "react-router-dom";

// actions
import { AddTarjeta } from "../../Actions/TarjetaAction";

// services
import { saveTarjetas } from "../../Services/tarjetaService";
import { saveError } from "../../Services/errorService";
import Header from "../Header/Header";

// Sweet alert
import Swal from "sweetalert2";

// formik
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";

function AddTarjetas({ history }) {
  const dispatch = useDispatch();
  const tiposTarjetas = [
    { key: 0, value: "CREDITO" },
    { key: 1, value: "DEBITO" },
  ];
  const formik = useFormik({
    initialValues: {
      nombre: "",
      tipoTarjeta: "",
    },
    validationSchema: Yup.object({
      nombre: Yup.string().required("Obligatorio."),
      tipoTarjeta: Yup.object().required("Obligatorio."),
    }),
    onSubmit: (values) => addTarjetaSubmit(values),
  });

  const addTarjetaSubmit = (values) => {
    // creamos el departamento
    let tarjeta = {
      ...values,
      tipoTarjeta: values.tipoTarjeta.key,
    };
    saveTarjetas(tarjeta)
      .then((res) => {
        Swal.fire({
          text: "Tarjeta creada correctamente.",
          type: "success",
          showConfirmButton: false,
          timer: 1000,
        });
        dispatch(AddTarjeta(res));
        history.goBack();
      })
      .catch(async (error) => {
        const errorData = {
          mensaje: "Error al intentar crear la tarjeta",
          detalle: error.message,
          entidad: "TARJETA",
          tipo: "WEBAPP",
          severidad: "HIGH",
          reportado: "APPLICATION",
        };

        await saveError(errorData);
        Swal.fire({
          text: "No se pudo crear la tarjeta. Verificar datos.",
          type: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faCreditCard} title={"Tarjetas"} />
        </div>

        <div className="bg-gris p-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputNombre">Nombre</label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faCreditCard} />
                  </span>
                  <input
                    type="text"
                    id="inputNombre"
                    name="nombre"
                    className="form-control"
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.nombre && formik.errors.nombre ? (
                  <small className="form-text text-danger">
                    {formik.errors.nombre}
                  </small>
                ) : null}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="inputCodigo">Tipo Tarjeta</label>
                <div>
                  <Select
                    placeholder={"Seleccionar un tipo de tarjeta"}
                    options={tiposTarjetas}
                    onChange={(value) =>
                      formik.setFieldValue("tipoTarjeta", value)
                    }
                    getOptionValue={(values) => values.key}
                    getOptionLabel={(values) => values.value}
                  ></Select>
                </div>
                {formik.touched.tipoTarjeta && formik.errors.tipoTarjeta ? (
                  <small className="form-text text-danger">
                    {formik.errors.tipoTarjeta}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-outline-success">
                <FontAwesomeIcon icon={faPlus} /> Crear Tarjeta
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(AddTarjetas);
