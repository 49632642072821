import React, { Fragment, useEffect, useState } from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faDonate,
  faSearch,
  faTrash,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";

// components
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";
import ItemOferta from "./ItemOferta";
import Header from "../Header/Header";

// route
import { withRouter } from "react-router-dom";

// services
import { getAllOfertas } from "../../Services/ofertaService";
import { getAllSucursales } from "../../Services/sucursalService";

// Sweet
import Swal from "sweetalert2";

const Ofertas = ({ history }) => {
  let token = JSON.parse(atob(sessionStorage.getItem("token").split(".")[1]));
  let rol = token.authorities[0];

  const [isLoad, setIsLoad] = useState(true);
  const [ofertas, setOfertas] = useState([]);
  const [sucursal, setSucursal] = useState(token.sucursal);
  const [sucursales, setSucursales] = useState([]);

  // pagination
  const [page, setPage] = useState(0);
  const [totalRegister, setTotalRegister] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (rol === "ROLE_ADMIN_MAGENTA") {
      getAllSucursales(true, 0, "", 1000)
        .then((res) => {
          setSucursales(res.data.content);
        })
        .catch((err) => console.log("error get sucursal"));
      getAllOfertas(true, page, query, sucursal.id)
        .then((res) => {
          setOfertas(res.data.content);
          setTotalRegister(res.data.totalElements);
          setTotalPage(res.data.totalPages);
          setIsLoad(false);
        })
        .catch((err) => console.log("error"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad, sucursal]);

  const goToDeleted = () => {
    history.push("/ofertas-eliminadas", { sucursalMemory: sucursal });
  };

  const nextPage = () => {
    if (totalPage === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    if (page === totalPage - 1) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllOfertas(true, page + 1, query, sucursal.id)
      .then((res) => {
        setPage(page + 1);
        setOfertas(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const previousPage = () => {
    if (page === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllOfertas(true, page - 1, query, sucursal.id)
      .then((res) => {
        setPage(page - 1);
        setOfertas(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const handleBuscador = (e) => {
    setQuery(e.target.value);
    if (e.key === "Enter") {
      // verificar si la query isEmpty hacer otra consulta
      getAllOfertas(true, page, e.target.value, sucursal.id)
        .then((res) => {
          setPage(0);
          setOfertas(res.data.content);
          setTotalRegister(res.data.totalElements);
          setTotalPage(res.data.totalPages);
          setIsLoad(false);
        })
        .catch((err) => console.log("error"));
    }
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faDonate} title={"Ofertas"} />
        </div>

        <div className="d-flex bd-highlight">
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-outline-success btn-lg"
              onClick={() => {
                history.push("/add-oferta");
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Crear
            </button>
          </div>

          <div className="p-2 bd-highlight">
            <button
              className="btn btn-outline-danger btn-lg"
              onClick={goToDeleted}
            >
              <FontAwesomeIcon icon={faTrash} /> Eliminado
            </button>
          </div>

          <div className="p-2 flex-grow-1 bd-highlight">
            <div className="input-group-prepend input-group-lg">
              <input
                type="text"
                className="form-control form-lg"
                id="inputNombre"
                name="precio"
                placeholder="Buscar"
                onKeyPress={(e) => handleBuscador(e)}
              />
              <span className="input-group-text" id="inputGroupPrepend">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
          </div>
          {rol === "ROLE_ADMIN_MAGENTA" ? (
            <div className="p-2 bd-highlight">
              <div className="dropdown">
                <button
                  className="btn btn-outline-info btn-lg dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <FontAwesomeIcon icon={faBuilding} /> {`${sucursal.nombre}`}
                </button>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="dropdownMenuButton"
                >
                  {sucursales.map((sucursal) => {
                    return (
                      <button
                        key={sucursal.id}
                        className="dropdown-item"
                        onClick={() => setSucursal(sucursal)}
                      >
                        {sucursal.nombre}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="bg-gris p-3">
          <div className="d-flex flex-row bd-highlight">
            <div className="p-2 bd-highlight">
              <button
                className="btn btn-outline-info"
                onClick={() => previousPage()}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo; Anterior</span>
              </button>
            </div>
            <div className="p-2 bd-highlight mt-1">
              Registros: {totalRegister} | Pagina actual: {page + 1} | Total
              páginas : {totalPage}
            </div>
            <div className="p-2 bd-highlight">
              <button
                className="btn btn-outline-info"
                onClick={() => nextPage()}
                aria-label="Next"
              >
                <span aria-hidden="true">Siguiente &raquo;</span>
              </button>
            </div>
          </div>

          <table className="table table-striped mt-3 text-center">
            <thead>
              <tr>
                <th scope="col">Id</th>
                <th scope="col">Nombre</th>
                <th scope="col">Articulo</th>
                <th scope="col">Fecha Fin</th>
                <th scope="col">Porcentaje Descuento</th>
                <th scope="col">Precio Final</th>
                <th scope="col">Cantidad</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {ofertas.map((oferta) => (
                <ItemOferta
                  key={parseInt(oferta.id)}
                  oferta={oferta}
                  setIsLoad={setIsLoad}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Ofertas);
