import axios from 'axios';
import {url} from './global';

export const getAllCuentasCorrientesPage = async (activo, page, nombre, size = 50) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/v2/cuenta-corriente/clientes?${nombre?`nombre=${nombre}&`:""}activo=${activo}&page=${page}&size=${size}`,{headers});
    return res;
}

export const getByIdCuentasCorriente = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/v2/cuenta-corriente/clientes/${id}`,{headers});
    return res;
}

export const editByIdCuentasCorriente = async (cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/v2/cuenta-corriente/clientes/${cuenta.id}`, cuenta,{headers});
    return res;
}

export const deleteByIdCuentasCorriente = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/v2/cuenta-corriente/clientes/${id}`,{headers});
    return res;
}

export const recoverByIdCuentasCorriente = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/v2/cuenta-corriente/clientes/${id}/recover`,{}, {headers});
    return res;
}

export const saveCuentasCorriente = async (cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/v2/cuenta-corriente/clientes`,cuenta,{headers});
    return res;
}

export const ventaCuentasCorrienteByCliente = async (dni, cuenta) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/cuentas-corrientes-venta/${dni}`, cuenta ,{headers});
    return res;
}