import React, { Fragment } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';
import ItemDetalleCaja from './ItemDetalleCaja';

const ModalDetalleCaja = ({ caja }) => {

    const estado = (estado) => {
        if (estado === "ABIERTA") return <p className="text-success">ABIERTA</p>;
        else return <p className="text-primary">CERRADA</p>;
    }

    return (
        <Fragment>

            <button type="button" className="btn text-info"
                data-toggle="modal" data-target={"#modalCajas" + caja.id}>
                <FontAwesomeIcon icon={faList} /> Detalle
            </button>

            <div className="modal fade" id={"modalCajas" + caja.id} tabIndex="-1" role="dialog" aria-labelledby="modalCajasTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalCajasTitle"><b>Detalle Caja</b></h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <p className="col-md"><b>Fecha:</b></p>
                                <p className="col-md"><b>Total:</b></p>
                                <p className="col-md"><b>Estado:</b></p>
                            </div>
                            <div className="row">
                                <p className="col-md">{caja.createdAt.split('T')[0]}</p>
                                <p className="col-md">${(caja.montoFinal) ?
                                    `${parseFloat(caja.montoFinal).toFixed(2)}` : "0.0"
                                }</p>
                                <p className="col-md">{estado(caja.estado)}</p>
                            </div>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Motivo</th>
                                        <th scope="col">Descripcion</th>
                                        <th scope="col">Cant. Billetes</th>
                                        <th scope="col">Cant. Cupones</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Observacion</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {caja.detalles.map((item) => (
                                        <ItemDetalleCaja
                                            key={parseInt(item.id)}
                                            item={item}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-success" onClick={() => { }} >Imprimir</button>
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalDetalleCaja);