import React, { Fragment, useState } from 'react';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'

// router
import { withRouter } from 'react-router-dom';

// models
import ModalDetallePago from '../ModalDetallePago/ModalDetallePago';
import ItemDetalleMedioPago from './ItemDetalleMedioPago';

// services
import { getAllVentasMediospagosByIdVenta } from '../../../Services/ventaMedioPagoService';


const ModalDetalleMedioPago= ({item}) => {

    const [mediosPagos, setMediosPagos] = useState([]);
    const [mostrarBotonHistorialPagos, setMostrarBotonHistorialPagos] = useState(false);

    const estado = (estado) => {
        if (estado === 'PAGADA') return <p className="text-success">PAGADA</p>;
        if (estado === 'PAGADA_PARCIALMENTE') return <p className="text-warning">PAGADA PARCIALMENTE</p>;
        if (estado === 'ADEUDADA') return <p className="text-primary">ADEUDADA</p>;
        if (estado === 'CANCELADA') return <p className="text-warning">CANCELADA</p>;
    }

    const getMedioPagoByVenta = () => {
        getAllVentasMediospagosByIdVenta(item.id)            
            .then(res => {                
                if(res.data.length > 0){                    
                    const contieneCCComoMedioDePago = res.data.find(elemento => elemento.medioPago.id === 4);                    
                    if (contieneCCComoMedioDePago){
                        setMostrarBotonHistorialPagos(true);
                    }else{
                        setMostrarBotonHistorialPagos(false);
                    }            
                }
                setMediosPagos(res.data);
            })
            .catch(res => {
                console.log('error',res);
            })
    }
 
    return (
        <Fragment>

            <button type="button" className="btn text-danger"
                data-toggle="modal" data-target={"#modalMediosPagosVenta"+item.id}
                onClick={() => getMedioPagoByVenta()}>
                <FontAwesomeIcon icon={faCreditCard}/> Medios de pago
            </button>

            <div className="modal fade" id={"modalMediosPagosVenta"+item.id} tabIndex="-1" role="dialog" aria-labelledby="modalMediosPagosVentaTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header justify-content-center">
                            <h5 className="modal-title" id="modalMediosPagosVentaTitle"><b>Medios de pago</b></h5>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <span className="col-md"><b>Fecha:</b></span>
                                <span className="col-md"><b>Total:</b></span>
                                <span className="col-md"><b>Estado:</b></span>
                            </div>
                            <div className="row">
                                <span className="col-md">{item.createdAt.split('T')[0]}</span>
                                <span className="col-md">${(item.total).toFixed(2)}</span>
                                <span className="col-md">{estado(item.estado)}</span>
                            </div>
                            <table className="table table-striped mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Medio de pago</th>
                                        <th scope="col">Monto</th>
                                        <th scope="col">Recargo</th>
                                        <th scope="col">Descuento</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {mediosPagos.map((item) => (
                                        
                                        <ItemDetalleMedioPago
                                            key={parseInt(item.id)}
                                            item = {item}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            {mostrarBotonHistorialPagos &&
                                <ModalDetallePago item={item}/>                        
                            }
                            
                            
                            <button type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>

        </Fragment>
    );

}

export default withRouter(ModalDetalleMedioPago);