import React, { Fragment, useState, useEffect } from "react";

// components
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";
import Header from "../Header/Header";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUser,
  faUserCircle,
  faEnvelope,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";

// router
import { withRouter } from "react-router-dom";

// services
import { register } from "../../Services/usuarioService";
import { getAllRoles } from "../../Services/roleService";
import { saveError } from "../../Services/errorService";
import { getAllSucursales } from "../../Services/sucursalService";

// modals
import Swal from "sweetalert2";

// Formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

function AddUsuario({ history }) {
  const [roles, setRoles] = useState([]);
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    getAllRoles()
      .then((res) => setRoles(res.data.content))
      .catch((err) => console.error(err));
    getAllSucursales(true, 0)
      .then((res) => setSucursales(res.data.content))
      .catch((err) => console.error(err));
  }, []);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      dni: "",
      username: "",
      password: "",
      passwordConfirm: "",
      correo: "",
      roleId: "",
      sucursalId: ""
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Obligatorio").min(4),
      password: Yup.string().required("Obligatorio").min(4),
      passwordConfirm: Yup.string()
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Las contraseñas no son iguales"
          ),
        })
        .required("Obligatorio"),
      roleId: Yup.number().required("Obligatorio"),
      sucursalId: Yup.number()
    }),
    onSubmit: (values) => addUsuarioSubmit(values),
  });

  const addUsuarioSubmit = (values) => {
    // creamos el usuario
    let usuario = {
      ...values,
      
    };

    register(usuario)
      .then((res) => {
        history.goBack();
      })
      .catch(async (error) => {
        const errorData = {
          mensaje: "Error al intentar crear un usuario",
          detalle: error.message,
          entidad: "USUARIO",
          tipo: "WEBAPP",
          severidad: "HIGH",
          reportado: "APPLICATION",
        };

        await saveError(errorData);
        console.log(error);
        Swal.fire({
          text: "El empleado ya posee un usuario o faltan datos.",
          type: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faUser} title={"Crear usuario"} />
        </div>

        <div className="bg-gris p-4">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputNombre">Nombre</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </div>
                  <input
                    type="text"
                    id="inputNombre"
                    name="nombre"
                    className="form-control"
                    placeholder="Nombre"
                    value={formik.values.nombre}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.nombre && formik.errors.nombre ? (
                  <small className="form-text text-danger">
                    {formik.errors.nombre}
                  </small>
                ) : null}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="inputApellido">Apellido</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </div>
                  <input
                    type="text"
                    id="inputApellido"
                    name="apellido"
                    className="form-control"
                    placeholder="Apellido"
                    value={formik.values.apellido}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.apellido && formik.errors.apellido ? (
                  <small className="form-text text-danger">
                    {formik.errors.apellido}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputDni">DNI</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </div>
                  <input
                    type="text"
                    id="inputDni"
                    name="dni"
                    className="form-control"
                    placeholder="DNI"
                    value={formik.values.dni}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.dni && formik.errors.dni ? (
                  <small className="form-text text-danger">
                    {formik.errors.dni}
                  </small>
                ) : null}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="inputEmail">Correo Electrónico</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                  </div>
                  <input
                    type="email"
                    id="inputEmail"
                    name="correo"
                    className="form-control"
                    placeholder="Correo Electrónico"
                    value={formik.values.correo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.correo && formik.errors.correo ? (
                  <small className="form-text text-danger">
                    {formik.errors.correo}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputUsername">Nombre de usuario</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faUserCircle} />
                    </span>
                  </div>
                  <input
                    type="text"
                    id="inputUsername"
                    name="username"
                    className="form-control"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.username && formik.errors.username ? (
                  <small className="form-text text-danger">
                    {formik.errors.username}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputPass">Contraseña</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faUserSecret} />
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPass"
                    name="password"
                    placeholder="********"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.password && formik.errors.password ? (
                  <small className="form-text text-danger">
                    {formik.errors.password}
                  </small>
                ) : null}
              </div>

              <div className="form-group col-md-6">
                <label htmlFor="inputPassConfirm">Confirmar contraseña</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faUserSecret} />
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPasswordConfirm"
                    name="passwordConfirm"
                    placeholder="********"
                    value={formik.values.passwordConfirm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                {formik.touched.passwordConfirm &&
                  formik.errors.passwordConfirm ? (
                  <small className="form-text text-danger">
                    {formik.errors.passwordConfirm}
                  </small>
                ) : null}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="inputRol">Rol</label>
                <div>
                  <Select
                    placeholder={"Seleccionar un rol"}
                    options={roles}
                    onChange={value => formik.setFieldValue('roleId', value.id)}
                    getOptionValue={(values) => values.id}
                    getOptionLabel={(values) => values.nombre}
                  >
                  </Select>
                </div>
                {formik.touched.roleId && formik.errors.roleId ?
                  <small className="form-text text-danger">
                    {formik.errors.roleId}
                  </small>
                  : null}
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="inputSucursal">Sucursal</label>
                <div>
                  <Select
                    placeholder={"Seleccionar una sucursal"}
                    options={sucursales}
                    onChange={value => formik.setFieldValue('sucursalId', value.id)}
                    getOptionValue={(values) => values.id}
                    getOptionLabel={(values) => values.nombre}
                  >
                  </Select>
                </div>
                {formik.touched.sucursalId && formik.errors.sucursalId ?
                  <small className="form-text text-danger">
                    {formik.errors.sucursalId}
                  </small>
                  : null}
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-outline-success">
                <FontAwesomeIcon icon={faPlus} /> Crear usuario
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(AddUsuario);
