import React, { Fragment, useState, useEffect } from "react";
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faDonate,
  faPercentage,
  faMoneyBill,
  faBox,
  faCalendarAlt,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { saveOferta } from "../../Services/ofertaService";
import { getAllSucursales } from "../../Services/sucursalService";
import Header from "../Header/Header";
import ModalArticulosOferta from "./ArticuloModalOfertas/ModalArticuloOferta";
import Swal from "sweetalert2";

const AddOfertas = ({ history }) => {
  let token = JSON.parse(atob(sessionStorage.getItem("token").split(".")[1]));
  let rol = token.authorities[0];

  const [fechaFinOferta, setFechaFinOferta] = useState("");
  const [porcentajeDescuento, setPorcentajeDescuento] = useState("");
  const [precioFinal, setPrecioFinal] = useState("");
  const [nombre, setNombre] = useState("");
  const [articulo, setArticulo] = useState("");
  const [promocionCantidad, setPromocionCantidad] = useState("");
  const [isLoad, setIsLoad] = useState(true);
  const [sucursal, setSucursal] = useState(token.sucursal.id);
  const [sucursales, setSucursales] = useState([]);

  useEffect(() => {
    if (rol !== "ROLE_ADMIN_MAGENTA") history.goBack();
    if (isLoad) {
      getAllSucursales(true, 0, "", 1000)
        .then((res) => {
          setSucursales(res.data.content);
          setIsLoad(false);
        })
        .catch((err) => console.log("Error al obtener sucursales"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad]);

  const handleCreateOferta = () => {

    const ofertaBody = {
      precioFinal: precioFinal,
      porcentajeDescuento: porcentajeDescuento,
      nombre: nombre,
      articuloId: articulo.id,
      sucursalId: sucursal,
      promocionCantidad: promocionCantidad,
      fechaFinOferta: fechaFinOferta,
    };

    saveOferta(ofertaBody)
      .then((res) => {
        history.goBack();
        Swal.fire({
          title: "Se ingreso la oferta correctamente.",
          type: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        Swal.fire({
          toast: true,
          position: "top",
          title: "No se pudo ingresar la oferta.",
          text: "Verifique los campos obligatorios.",
          icon: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faDonate} title={"Ofertas"} />
        </div>

        <div className="bg-gris p-4">
          <div>
            <div className="form-row">
              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputNombre">Artículo</label>
                <div className="input-group-prepend">
                  <ModalArticulosOferta setCodigoArticulo={setArticulo} />
                  <input
                    type="text"
                    id="inputNombre"
                    name="nombre"
                    defaultValue={articulo.nombre}
                    className="form-control"
                    placeholder="Ingrese el artículo"
                    disabled={true}
                    onChange={(e) => setArticulo(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputFechaFin">Fecha Fin</label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </span>
                  <input
                    type="date"
                    className="form-control"
                    id="inputFechaFin"
                    name="fechaFinOferta"
                    onChange={(e) => setFechaFinOferta(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputNombre">Nombre oferta</label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faDonate} />
                  </span>
                  <input
                    type="text"
                    id="inputNombre"
                    name="nombre"
                    className="form-control"
                    placeholder="Ej. Lácteos, Gaseosas..."
                    onChange={(e) => setNombre(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputPrecioFinal">Precio Final</label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faMoneyBill} />
                  </span>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="inputPrecioFinal"
                    name="precioFinal"
                    placeholder="Ingrese precio final"
                    defaultValue={articulo.precioFinal ? articulo.precioFinal : articulo.precioFinalCalculado}
                    onChange={(e) => setPrecioFinal(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputPorcentajeDescuento">
                  Porcentaje Descuento
                </label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faPercentage} />
                  </span>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="inputPorcentajeDescuento"
                    name="porcentajeDescuento"
                    placeholder="Ingrese porcentaje de descuento"
                    onChange={(e) => setPorcentajeDescuento(e.target.value)}
                  />
                </div>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputCantidadArticulo">
                  Cantidad de Artículos
                </label>
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    <FontAwesomeIcon icon={faBox} />
                  </span>
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="inputCantidadArticulo"
                    name="promocionCantidad"
                    placeholder="Ingrese la cantidad de artículos"
                    onChange={(e) => setPromocionCantidad(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {rol === "ROLE_ADMIN_MAGENTA" && (
              <div className="form-row">
                <div className="form-group col-md-4 text-left">
                  <label htmlFor="inputSucursal">Sucursal</label>
                  <div className="input-group-prepend justify-content-center">
                    <span className="input-group-text" id="inputGroupPrepend">
                      <FontAwesomeIcon icon={faStore} />
                    </span>
                    <select
                      id="inputSucursal"
                      className="custom-select"
                      name="sucursal"
                      onChange={(e) => setSucursal(e.target.value)}
                      value={sucursal || ""}
                    >
                      <option value={null}>-- Seleccione una sucursal --</option>
                      {sucursales.map((s) => (
                        <option key={s.id} value={s.id}>
                          {s.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}


            <div className="text-center mt-4">
              <button
                type="submit"
                className="btn btn-outline-success"
                onClick={handleCreateOferta}
              >
                <FontAwesomeIcon icon={faPlus} /> Crear Oferta
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(AddOfertas);
