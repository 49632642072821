import React, { Fragment, useEffect, useState } from "react";

// icons
import { faBarcode, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";
import ItemFamiliaEliminada from "./ItemFamiliaEliminada";

// services
import { getAllFamilias } from "../../Services/familiaService";

// route
import { withRouter } from "react-router-dom";
import Header from "../Header/Header";

// alert swal
import Swal from "sweetalert2";

function FamiliaEliminada({ history }) {
  let token = JSON.parse(atob(sessionStorage.getItem("token").split(".")[1]));
  let rol = token.authorities[0];

  const [familias, setFamilias] = useState([]);
  const [isLoad, setIsLoad] = useState(true);

  // pagination
  const [page, setPage] = useState(0);
  const [totalRegister, setTotalRegister] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    if (rol !== "ROLE_ADMIN_MAGENTA") history.goBack();
    if (isLoad) {
      getAllFamilias(false, 0)
        .then((res) => {
          setFamilias(res.data.content);
          setTotalRegister(res.data.totalElements);
          setTotalPage(res.data.totalPages);
          setIsLoad(false);
        })
        .catch((error) => console.log(error.response));
    } else {
      setIsLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad]);

  const nextPage = () => {
    if (totalPage === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    if (page === totalPage - 1) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllFamilias(false, page + 1)
      .then((res) => {
        setPage(page + 1);
        setFamilias(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const previousPage = () => {
    if (page === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllFamilias(false, page - 1)
      .then((res) => {
        setPage(page - 1);
        setFamilias(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const handleBuscador = (e) => {
    if (e.key === "Enter") {
      let data = e.target.value; // Aquí simplemente pasamos el valor de la consulta
      getAllFamilias(false, 0, data)
        .then((res) => {
          setPage(0);
          setFamilias(res.data.content);
          setTotalRegister(res.data.totalElements);
          setTotalPage(res.data.totalPages);
          setIsLoad(false);
        })
        .catch((err) => console.log("error"));
    }
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faBarcode} title={"Familias Eliminadas"} />
        </div>

        <div className="d-flex bd-highlight">
          <div className="p-2 flex-grow-1 bd-highlight">
            <div className="input-group-prepend input-group-lg">
              <span className="input-group-text" id="inputGroupPrepend">
                <FontAwesomeIcon icon={faSearch} />
              </span>

              <input
                type="text"
                className="form-control form-lg"
                id="inputNombre"
                name="precio"
                placeholder="Buscar"
                onKeyPress={(e) => handleBuscador(e)}
              />
            </div>
          </div>
        </div>

        <div className="bg-gris p-3">
          <div className="d-flex flex-row bd-highlight">
            <div className="p-2 bd-highlight">
              <button
                className="btn btn-outline-info"
                onClick={() => previousPage()}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo; Anterior</span>
              </button>
            </div>
            <div className="p-2 bd-highlight mt-1">
              Registros: {totalRegister} | Pagina actual: {page + 1} | Total
              páginas : {totalPage}
            </div>
            <div className="p-2 bd-highlight">
              <button
                className="btn btn-outline-info"
                onClick={() => nextPage()}
                aria-label="Next"
              >
                <span aria-hidden="true">Siguiente &raquo;</span>
              </button>
            </div>
          </div>

          <table className="table table-striped mt-3 text-center">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nombre</th>
                <th scope="col">Ganancia</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {familias.map((familia) => (
                <ItemFamiliaEliminada
                  key={parseInt(familia.id)}
                  familia={familia}
                  setIsLoad={setIsLoad}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
}

export default withRouter(FamiliaEliminada);
