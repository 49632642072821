import React, { Fragment, useEffect, useState } from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faUsers,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

// components
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";
import ItemUsuario from "./ItemUsuario";
import Header from "../Header/Header";

// route
import { withRouter } from "react-router-dom";

// services
import { getAllUsuarios } from "../../Services/usuarioService";

// alert swal
import Swal from "sweetalert2";

const Usuarios = ({ history }) => {
  let token = JSON.parse(atob(sessionStorage.getItem("token").split(".")[1]));
  let rol = token.authorities[0];

  const [isLoad, setIsLoad] = useState(true);
  const [usuarios, setUsuarios] = useState([]);

  //Pagination
  const [page, setPage] = useState(0);
  const [totalRegister, setTotalRegister] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    if (rol !== "ROLE_ADMIN_MAGENTA") history.goBack();
    if (isLoad) {
      getAllUsuarios(true, 0)
        .then((res) => {
          console.log(res.data.content);
          setUsuarios(res.data.content);
          setTotalRegister(res.data.totalElements);
          setTotalPage(res.data.totalPages);
          setIsLoad(false);
        })
        .catch((error) => console.log(error.response));
    } else {
      setIsLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad]);

  const goToDeleted = () => {
    history.push("/usuarios-eliminados");
  };

  const nextPage = () => {
    if (totalPage === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    if (page === totalPage - 1) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllUsuarios(true, page + 1)
      .then((res) => {
        setPage(page + 1);
        setUsuarios(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const previousPage = () => {
    if (page === 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No hay mas páginas",
        type: "info",
        showConfirmButton: false,
        timer: 1000,
      });
      return;
    }
    getAllUsuarios(true, page - 1)
      .then((res) => {
        setPage(page - 1);
        setUsuarios(res.data.content);
        setTotalRegister(res.data.totalElements);
        setTotalPage(res.data.totalPages);
        setIsLoad(false);
      })
      .catch((err) => console.log("error"));
  };

  const handleBuscador = (e) => {
    if (e.key === "Enter") {
      let data = e.target.value;
      getAllUsuarios(true, 0, data)
        .then((res) => {
          setPage(0);
          setUsuarios(res.data.content);
          setTotalRegister(res.data.totalElements);
          setTotalPage(res.data.totalPages);
          setIsLoad(false);
        })
        .catch((err) => console.log("error"));
    }
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faUsers} title={"Usuarios"} />
        </div>

        <div className="bg-gris p-3 d-flex justify-content-between align-items-center">
          <div>
            <button
              className="btn btn-outline-success btn-lg mr-2"
              onClick={() => {
                history.push("/add-usuario");
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Crear
            </button>
            <button
              className="btn btn-outline-danger btn-lg"
              onClick={goToDeleted}
            >
              <FontAwesomeIcon icon={faTrash} /> Eliminados
            </button>
          </div>
          <div className="p-2 flex-grow-1 bd-highlight">
            <div className="input-group-prepend input-group-lg">
              <span className="input-group-text" id="inputGroupPrepend">
                <FontAwesomeIcon icon={faSearch} />
              </span>
              <input
                type="text"
                className="form-control form-lg"
                id="inputNombre"
                name="precio"
                placeholder="Buscar"
                onKeyPress={(e) => handleBuscador(e)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-row bd-highlight">
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-outline-info"
              onClick={() => previousPage()}
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo; Anterior</span>
            </button>
          </div>
          <div className="p-2 bd-highlight mt-1">
            Registros: {totalRegister} | Página actual: {page + 1} | Total
            páginas : {totalPage}
          </div>
          <div className="p-2 bd-highlight">
            <button
              className="btn btn-outline-info"
              onClick={() => nextPage()}
              aria-label="Next"
            >
              <span aria-hidden="true">Siguiente &raquo;</span>
            </button>
          </div>
        </div>

        <table className="table table-striped mt-3 text-center">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Nombre</th>
              <th scope="col">Apellido</th>
              <th scope="col">Correo</th>
              <th scope="col">Nombre de usuario</th>
              <th scope="col">Rol</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {usuarios.map((usuario) => {
              return (
                <ItemUsuario
                  key={parseInt(usuario.id)}
                  usuario={usuario}
                  setIsLoad={setIsLoad}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default withRouter(Usuarios);
