import React, { Fragment } from 'react';

// router
import { withRouter } from 'react-router-dom';

// components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo';
import Header from '../Header/Header';

// icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faMapMarkedAlt, faPhoneAlt, faEdit } from '@fortawesome/free-solid-svg-icons'

// services
import { editByIdSucursal } from '../../Services/sucursalService';

//formik
import { useFormik } from "formik";
import * as Yup from "yup";

const EditSucursal = ({ history, location }) => {

    const sucursal = location.state.sucursal;

    const formik = useFormik({
        initialValues: {
            nombre: sucursal.nombre,
            telefono: sucursal.telefono || "",
            direccion: sucursal.direccion || ""
        },
        validationSchema: Yup.object({
            nombre: Yup.string().test('len', "minimo 3 y maximo 30 caracteres", (val) => {
                if (val === undefined) {
                    return true;
                }
                return val.length === 0 || (val.length >= 3 && val.length <= 30);
            }),
            telefono: Yup.string(),
            direccion: Yup.string(),
        }),
        onSubmit: (values) => editSucursalSubmit(values),
    });

    const editSucursalSubmit = (values) => {

        // formamos la sucursal editada
        let sucursalUpdated = {
            ...values,
            id: sucursal.id
        }

        editByIdSucursal(sucursalUpdated)
            .then(res => {
                history.goBack();
            })
            .catch(error => console.log(error.response.data));

    }

    return (
        <Fragment>
            <Header />
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faBuilding}
                        title={'Sucursales'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Nombre</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="nombre"
                                        className="form-control"
                                        placeholder="Ej. Lacteos, Gaseosas..."
                                        value={formik.values.nombre}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange} />
                                </div>
                                {formik.touched.nombre && formik.errors.nombre ? (
                                    <small className="form-text text-danger">
                                        {formik.errors.nombre}
                                    </small>
                                ) : null}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputNombre">Direccion</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                                    </span>
                                    <input type="text"
                                        id="inputNombre"
                                        name="direccion"
                                        className="form-control"
                                        placeholder="Ej. Piedras 2120..."
                                        value={formik.values.direccion}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange} />
                                </div>
                            </div>
                            {formik.touched.direccion && formik.errors.direccion ? (
                                <small className="form-text text-danger">
                                    {formik.errors.direccion}
                                </small>
                            ) : null}
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodigo">Telefono</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faPhoneAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCodigo"
                                        name="telefono"
                                        placeholder="Ej. 4324101"
                                        value={formik.values.telefono}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleChange} />
                                </div>
                            </div>
                            {formik.touched.telefono && formik.errors.telefono ? (
                                <small className="form-text text-danger">
                                    {formik.errors.telefono}
                                </small>
                            ) : null}
                        </div>

                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-danger">
                                <FontAwesomeIcon icon={faEdit} /> Editar Sucursal</button>
                        </div>


                    </form>
                </div>

            </div>
        </Fragment>
    );
};

export default withRouter(EditSucursal);