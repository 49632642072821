import React, { Fragment, useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faTrashAlt,
  faBoxes,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import BotonBack from "../Utiles/BotonBack";
import Subtitulo from "../Utiles/Subtitulo";
import ModalArticuloCompra from "./ArticuloModalCompra/ModalArticuloCompra";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
// services
import { saveCompra } from "../../Services/compraService";
import { getAllProveedores } from "../../Services/proveedorService";
import { getAllComprobantes } from "../../Services/comprobanteService";
import { getArticuloByCodigo } from "../../Services/articuloService";
import { getAllSucursales } from "../../Services/sucursalService";
import { getAllEmpleados } from "../../Services/empleadoService";
import Header from "../Header/Header";

const AddCompra = ({ history }) => {
  let token = JSON.parse(atob(sessionStorage.getItem("token").split(".")[1]));
  let rol = token.authorities[0];

  // datos de compra
  const [proveedores, setProveedores] = useState([]);
  const [proveedor, setProveedor] = useState();

  const [comprobantes, setComprobantes] = useState([]);
  const [comprobante, setComprobante] = useState();

  const [numeroComprobante, setNumeroComprobante] = useState();
  const [total, setTotal] = useState();
  const [fechaCompra, setFechaCompra] = useState(new Date().toISOString());
  const [estado, setEstado] = useState("ADEUDADA");
  const estados = ["PAGADA", "ADEUDADA"];

  const [itemsCompra, setItemsCompra] = useState([]);

  const [items, setItems] = useState([]);
  const [codigoArticulo, setCodigoArticulo] = useState("");
  const [precioArticulo, setPrecioArticulo] = useState();

  const inputTotalRef = useRef(null);
  const [isLoad, setIsLoad] = useState(true);

  const [sucursal, setSucursal] = useState(token.sucursal.id);
  const [sucursales, setSucursales] = useState([]);
  const [empleado, setEmpleado] = useState({});
  const [empleados, setEmpleados] = useState([]);

  //const { register, handleSubmit, errors } = useForm();

  const [errorProveedor, setErrorProveedor] = useState(false);
  const [errorTipoComprobante, setErrorTipoComprobante] = useState(false);
  const [errorNumeroComprobante, setErrorNumeroComprobante] = useState(false);
  const [errorTotal, setErrorTotal] = useState(false);
  const [errorFechaCompra, setErrorFechaCompra] = useState(false);

  useEffect(() => {
    if (rol !== "ROLE_ADMIN_MAGENTA") history.goBack();
    if (isLoad) {
      inputTotalRef.current.focus();
      // cargo comprobantes
      getAllComprobantes()
        .then((res) => {
          setComprobantes(res.data);
        })
        .catch((error) => console.log("no se cargaron los comprobantes"));

      getAllEmpleados(true, 0, "", 50)
        .then((res) => {
          setEmpleados(res.data.content);
        })
        .catch((error) => console.log("no se cargaron los empleados"));

      getAllSucursales(true, 0, "", 1000)
        .then((res) => {
          setSucursales(res.data.content);
        })
        .catch((err) => console.log("error get sucursal"));
      // cargo proveedores
      getAllProveedores(true, 0, "", 50)
        .then((res) => {
          setProveedores(res.data.content);
        })
        .catch((error) => console.log("no se cargaron los proveedores"));
      console.log(fechaCompra);
    } else {
      setIsLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoad]);

  // manejo de input cuando agregan articulos
  const handleKeyPress = (event) => {
    //event.preventDefault();
    if (event.key === "Enter") {
      // codigo y cantidad

      let cantidad = 1;
      let codigo;

      if (codigoArticulo.toString().indexOf("*") !== -1) {
        let valores = codigoArticulo.split("*");
        cantidad = parseInt(valores[0]);
        codigo = valores[1];
      } else {
        codigo = codigoArticulo;
      }

      // validar que el articulo no existe en los detalles
      let isPresent = false;
      items.forEach((item) => {
        if (parseInt(item.codigo, 10) === parseInt(codigo, 10))
          isPresent = true;
      });

      // si ya se encuentra presento salgo de la funcion
      if (isPresent) {
        Swal.fire({
          title: "El articulo ya se ingreso en esta venta",
          type: "info",
          showConfirmButton: false,
          timer: 1000,
        });
        return;
      }

      getArticuloByCodigo(codigo)
        .then(async (data) => {
          // datos para calcular precio articulo
          let articulo = data.data;

          // items para mostra detalle en pantalla
          let item = {
            id: articulo.id,
            codigo: articulo.codigo,
            precio: precioArticulo,
            articulo: articulo.nombre,
            marca: articulo.marca.nombre,
            cantidad: cantidad,
          };

          // items para guardar en la base de datos
          let itemCompra = {
            articulo: articulo,
            cantidad: item.cantidad,
            precio: item.precio,
          };

          // ingresamos items que se guardaran en la bd
          setItemsCompra([...itemsCompra, itemCompra]);
          // ingresamos items de  que se mostraran en pantalla
          setItems([...items, item]);

          setCodigoArticulo("");
          setPrecioArticulo("");
          inputTotalRef.current.focus();
        })
        .catch((error) => console.log(error));
    }
  };

  // elimina items de la lista de articulos
  const deleteItem = (articulo) => {
    let itemsDelete = items.filter((item) => {
      return (
        parseInt(item.id, 10) !== parseInt(articulo.id, 10)
      );
    });

    let itemsCompraDelete = itemsCompra.filter((item) => {
      return (
        parseInt(item.articulo.id, 10) !==
        parseInt(articulo.id, 10)
      );
    });

    setItemsCompra(itemsCompraDelete);
    setItems(itemsDelete);
  };

  // cancela la venta por completo y hace un reset
  const cancelarCompra = () => {
    setItems([]);
    setItemsCompra([]);
    inputTotalRef.current.focus();
  };

  const realizarValidacionesEnInputs = () => {
    if (proveedor === undefined || proveedor === "") {
      setErrorProveedor(true);
    } else {
      setErrorProveedor(false);
    }
    if (comprobante === undefined || comprobante === "") {
      setErrorTipoComprobante(true);
    } else {
      setErrorTipoComprobante(false);
    }
    if (numeroComprobante === undefined || numeroComprobante === "") {
      setErrorNumeroComprobante(true);
    } else {
      setErrorNumeroComprobante(false);
    }
    if (total === undefined || total === "") {
      setErrorTotal(true);
    } else {
      setErrorTotal(false);
    }
    if (fechaCompra === undefined || fechaCompra === "") {
      setErrorFechaCompra(true);
    } else {
      setErrorFechaCompra(false);
    }
    if (
      errorProveedor === false ||
      errorTipoComprobante === false ||
      errorNumeroComprobante === false ||
      errorTotal === false ||
      errorFechaCompra === false
    ) {
      return true;
    } else return false;
  };

  const finalizarCompra = (e) => {
    //VALIDACIONES
    const inputsConDatos = realizarValidacionesEnInputs();
    if (!inputsConDatos) return;
    if (itemsCompra.length <= 0 || items.length <= 0) {
      Swal.fire({
        toast: true,
        position: "top",
        title: "No se puede ingresar una compra sin items.",
        type: "error",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }

    // completamos los datos de la venta
    let compraBody = {
      sucursalId: sucursal,
      empleadoId: empleado,
      proveedorId: proveedor,
      tipoComprobanteId: comprobante,
      numeroComprobante: numeroComprobante,
      total: total,
      items: itemsCompra,
      fecha: fechaCompra,
      estado,
    };

    // ingresamos el Compra en la bd
    saveCompra(compraBody)
      .then((res) => {
        history.goBack();
        Swal.fire({
          title: "Se ingreso la compra correctamente.",
          type: "success",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((error) => {
        Swal.fire({
          toast: true,
          position: "top",
          title: "No se pudo ingresar la compra.",
          text: "Verifique los campos obligatorios.",
          type: "error",
          showConfirmButton: false,
          timer: 2500,
        });
      });
  };

  return (
    <Fragment>
      <Header />
      <div className="container-fluid mt-3">
        <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
          <BotonBack />
          <Subtitulo icon={faBoxes} title={"Compras"} />
        </div>

        <div className="bg-gris centrado">
          <div>
            <div className="form-row justify-content-between">
              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputProveedor">Proveedor</label>
                <div className="input-group-prepend">
                  <select
                    id="inputProveedor"
                    className="custom-select"
                    name="proveedor"
                    onChange={(e) => setProveedor(e.target.value)}
                  >
                    <option value={null}>-- Seleccione un proveedor --</option>
                    {proveedores.map((p) => (
                      <option
                        key={parseInt(p.id)}
                        value={p.id}
                      >
                        {p.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="form-text text-danger">
                  {errorProveedor && `El campo proveedor es obligatorio`}
                </small>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputTipoComprobante">Tipo Comprobante</label>
                <div className="input-group-prepend">
                  <select
                    id="inputTipoComprobante"
                    className="custom-select"
                    name="tipoComprobante"
                    onChange={(e) => setComprobante(e.target.value)}
                  >
                    <option value={null}>
                      -- Seleccione un comprobante --
                    </option>
                    {comprobantes.map((c) => (
                      <option
                        key={parseInt(c.id)}
                        value={c.id}
                      >
                        {c.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <small className="form-text text-danger">
                  {errorTipoComprobante && `El campo proveedor es obligatorio`}
                </small>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputNComprobante">Nº Comprobante</label>
                <div className="input-group-prepend">
                  <input
                    type="text"
                    className="form-control"
                    id="inputNComprobante"
                    onChange={(e) => setNumeroComprobante(e.target.value)}
                    name="numeroComprobante"
                  />
                </div>
                <small className="form-text text-danger">
                  {errorNumeroComprobante &&
                    `El campo Nro.de Comrpobante es obligatorio`}
                </small>
              </div>
            </div>

            <div className="form-row justify-content-between">
              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputTotal">Total</label>
                <div className="input-group-prepend">
                  <input
                    type="number"
                    step="any"
                    className="form-control"
                    id="inputTotal"
                    onChange={(e) => setTotal(e.target.value)}
                    name="total"
                  />
                </div>
                <small className="form-text text-danger">
                  {errorTotal && `El campo Total es obligatorio`}
                </small>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputFecha">Fecha Compra</label>
                <div className="input-group-prepend">
                  <input
                    type="date"
                    className="form-control"
                    id="inputFecha"
                    onChange={(e) => setFechaCompra(e.target.value)}
                    name="fecha"
                    defaultValue={fechaCompra.split("T")[0]}
                  />
                </div>
                <small className="form-text text-danger">
                  {errorFechaCompra && `El campo Fecha es obligatorio`}
                </small>
              </div>

              <div className="form-group col-md-4 text-left">
                <label htmlFor="inputEstado">Estado</label>
                <div className="input-group-prepend">
                  <select
                    id="inputEstado"
                    className="custom-select"
                    name="tipoComprobante"
                    value={estado}
                    onChange={(e) => setEstado(e.target.value)}
                  >
                    {estados.map((c, index) => (
                      <option key={parseInt(index)} value={c}>
                        {c}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-row col-12">
                <div className="form-group col-md-6 text-left">
                  <label htmlFor="inputEmpleado">Empleado</label>
                  <div className="input-group-prepend">
                    <select
                      id="inputEmpleado"
                      className="custom-select"
                      name="empleado"
                      onChange={(e) => setEmpleado(e.target.value)}
                      value={empleado.id}
                    >
                      <option value={null}>-- Seleccione un empleado --</option>
                      {empleados.map((e) => (
                        <option key={e.id.persona.id} value={e.id.persona.id}>
                          {e.id.persona.nombre}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {rol === "ROLE_ADMIN_MAGENTA" && (
                  <div className="form-group col-md-6 text-left">
                    <label htmlFor="inputSucursal">Sucursal</label>
                    <div className="input-group-prepend">
                      <select
                        id="inputSucursal"
                        className="custom-select"
                        name="sucursal"
                        onChange={(e) => setSucursal(e.target.value)}
                        value={sucursal.id}
                      >
                        <option value={null}>-- Seleccione una sucursal --</option>
                        {sucursales.map((s) => (
                          <option key={s.id} value={s.id}>
                            {s.nombre}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>

            </div>

            <div className="d-flex bd-highlight">
              <div className="p-2 bd-highlight">
                <ModalArticuloCompra setCodigoArticulo={setCodigoArticulo} />
              </div>
              <div className="p-2 flex-grow-1 bd-highlight">
                <input
                  className="form-control form-control-lg"
                  style={{ height: 45, maxWidth: "90%" }}
                  type="text"
                  placeholder="Ingrese un articulo"
                  value={codigoArticulo}
                  ref={inputTotalRef}
                  onChange={(e) => setCodigoArticulo(e.target.value)}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </div>
            </div>

            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Producto</th>
                  <th scope="col">Marca</th>
                  <th scope="col">Cant.</th>
                  <th scope="col">Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {items
                  ? items.map((item) => {
                    return (
                      <tr key={parseInt(item.id, 10)}>
                        <th scope="row">
                          <h4>{item.codigo}</h4>
                        </th>
                        <td>
                          <h4>{item.articulo}</h4>{" "}
                        </td>
                        <td>
                          <h4>{item.marca}</h4>{" "}
                        </td>
                        <td>
                          <div className="input-group form-row justify-content-center">
                            <input
                              type="text"
                              className="form-control col-md-3"
                              style={{
                                textAlign: "center",
                                fontSize: 13,
                                fontWeight: "bold",
                              }}
                              defaultValue={item.cantidad}
                              disabled
                            />
                          </div>
                        </td>
                        <td>
                          <button
                            className="btn btn-outline-primary"
                            onClick={() => deleteItem(item)}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  : null}
              </tbody>
            </table>

            <div className="row justify-content-center">
              <button
                type="submit"
                className="btn btn-outline-success btn-lg col-md-4 mr-1"
                onClick={() => finalizarCompra()}
              >
                Ingresar compra <FontAwesomeIcon icon={faPlus} />
              </button>
              <button
                className="btn btn-outline-primary btn-lg col-md-4"
                onClick={() => cancelarCompra()}
              >
                Borrar <FontAwesomeIcon icon={faTimesCircle} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(AddCompra);
