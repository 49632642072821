import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faClipboardList, faUserCircle, faDollarSign, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'

// services
import { saveCuentasCorriente } from '../../Services/cuentaCorrienteClienteService';
import { getAllClientes } from '../../Services/clienteService';
import { saveError } from '../../Services/errorService';

//components
import BotonBack from '../Utiles/BotonBack';
import Subtitulo from '../Utiles/Subtitulo'

// alert swal
import Swal from 'sweetalert2'

// formik
import { useForm } from 'react-hook-form';
import validationsConfig from './ValidationsConfigCuentaCorrienteCliente';
import getErrorMessageInValidation from "../Utiles/validationsInForm";
import Header from '../Header/Header';

function AddCuentasCorrientes({ history }) {

    const [cliente, setCliente] = useState('');
    const [montoMaximo, setMontoMaximo] = useState('');
    const [cantidadCuotasMaximas, setCantidadCuotasMaximas] = useState(0);
    const [isLoad, setIsLoad] = useState(true);

    const [clientes, setClientes] = useState([]);

    const { register, handleSubmit, errors } = useForm();

    useEffect(() => {
        if (isLoad) {
            getAllClientes(true, 0, "", 10000)
                .then(res => {
                    setClientes(res.data.content);
                    setIsLoad(false);
                })
                .catch(err => console.log('error'));
        } else {
            setIsLoad(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoad]);


    const addCuentaCorrienteSubmit = (e) => {

        // creamos la sucursal
        let cuentaCorriente = {
            clienteId: cliente, montoMaximo, cantidadCuotasMaximas
        }        

        saveCuentasCorriente(cuentaCorriente)
            .then(res => {
                history.goBack();
            })
            .catch(async error => {
                const errorData = {
                    mensaje: "Error al intentar crear una cuenta corriente",
                    detalle: error.response.data.message,
                    entidad: "CUENTA_CORRIENTE_CLIENTE",
                    tipo: "WEBAPP",
                    severidad: "HIGH",
                    reportado: "APPLICATION",
                  };
          
                await saveError(errorData);
                Swal.fire({
                    title: 'La cuenta del cliente ya existe',
                    type: 'warning',
                    showConfirmButton: false,
                    timer: 1000
                });
                console.log(error.response.data.message)
            });

    }

    return (
        <Fragment>
            <Header/>
            <div className="container-fluid mt-3">
                <div className="alert alert-dismissible alert-secondary d-flex justify-content-between">
                    <BotonBack />
                    <Subtitulo
                        icon={faClipboardList}
                        title={'Cuentas Corrientes'} />
                </div>

                <div className="bg-gris p-4">
                    <form onSubmit={handleSubmit(addCuentaCorrienteSubmit)}>
                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputDepartamento">Cliente</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faUserCircle} />
                                    </span>
                                    <select id="inputDepartamento" 
                                            className="custom-select" 
                                            name="dueno"
                                            onChange={(e) => setCliente(e.target.value)}
                                            ref={register(validationsConfig.dueno)}
                                    >
                                        <option value={"null"}>-- Seleccione un Cliente --</option>
                                        {clientes.map((cliente, index) => (
                                            <option key={index} value={cliente.id.persona.id}>
                                                {cliente.id.persona.apellido} {cliente.id.persona.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <small className="form-text text-danger">
                                    {errors.dueno &&
                                    getErrorMessageInValidation(
                                        "dueno",        
                                        errors.dueno,    
                                        validationsConfig 
                                    )}
                                </small>
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor="inputMontoMaximo">Monto Maximo</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faDollarSign} />
                                    </span>
                                    <input type="text"
                                        id="inputMontoMaximo"
                                        name="montoMaximo"
                                        className="form-control"
                                        placeholder="Ej. $5000, $6000"
                                        onChange={e => setMontoMaximo(e.target.value)} 
                                        ref={register(validationsConfig.montoMaximo)}
                                    />
                                </div>
                                <small className="form-text text-danger">
                                    {errors.montoMaximo &&
                                    getErrorMessageInValidation(
                                        "montoMaximo",        
                                        errors.montoMaximo,    
                                        validationsConfig 
                                    )}
                                </small>
                            </div>

                        </div>

                        <div className="form-row">

                            <div className="form-group col-md-6">
                                <label htmlFor="inputCodigo">Cantidad Maxima de Cuotas</label>
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                        <FontAwesomeIcon icon={faMoneyCheckAlt} />
                                    </span>
                                    <input type="text"
                                        className="form-control"
                                        id="inputCodigo"
                                        name="cantidaMaximaCuotas"
                                        placeholder="Ej. 4324101"
                                        onChange={e => setCantidadCuotasMaximas(e.target.value)}
                                        ref={register(validationsConfig.cantidaMaximaCuotas)} 
                                    />
                                </div>
                                <small className="form-text text-danger">
                                    {errors.cantidaMaximaCuotas &&
                                    getErrorMessageInValidation(
                                        "cantidaMaximaCuotas",        
                                        errors.cantidaMaximaCuotas,    
                                        validationsConfig 
                                    )}
                                </small>
                            </div>
                        </div>


                        <div className="text-center">
                            <button type="submit" className="btn btn-outline-success">
                                <FontAwesomeIcon icon={faPlus} /> Crear Cuenta Corriente</button>
                        </div>


                    </form>
                </div>



            </div>
        </Fragment>
    );
}

export default withRouter(AddCuentasCorrientes);