import axios from 'axios';
import {url} from './global';

export const savePresupuesto = async (presupuesto) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/v2/presupuestos`, presupuesto,{headers});
    return res;
}

export const getPresupuestoPage = async (activo, page, nombre, sucursal, size = 50) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/v2/presupuestos?${nombre?`nombre=${nombre}&`:""}&sucursal=${sucursal}&activo=${activo}&page=${page}&size=${size}`,{headers});
    return res;
}

export const deletePresupuesto = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/v2/presupuestos/${id}`,{headers});
    return res;
}

export const recoverPresupuesto = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/v2/presupuestos/${id}/recover`, {},{headers});
    return res;
}
