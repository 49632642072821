import axios from 'axios';
import {url} from './global';


export const getAllSucursales = async (activo, page, nombre, size = 50) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/v2/sucursales?${nombre?`nombre=${nombre}&`:""}activo=${activo}&page=${page}&size=${size}`,{headers});
    return res;
}

export const getByIdSucursal = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/v2/sucursales/${id}`,{headers});
    return res;
}

export const editByIdSucursal = async (sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/v2/sucursales/${sucursal.id}`, sucursal,{headers});
    return res;
}

export const deleteByIdSucursal = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/v2/sucursales/${id}`,{headers});
    return res;
}

export const saveSucursal = async (sucursal) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/v2/sucursales`,sucursal,{headers});
    return res;
}

export const recoverSucursalDeleted = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/v2/sucursales/${id}/recover`, {}, { headers });
    return res;
}
