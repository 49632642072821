import React from "react";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

// routes
import { withRouter } from "react-router-dom";

// Sweet alert
import Swal from "sweetalert2";

// services
import { deleteByIdProveedor } from "../../Services/proveedorService";

const ItemProveedor = ({ proveedor, history, setIsLoad }) => {
  const btnEliminarProveedor = (id) => {
    Swal.fire({
      title: "Estas seguro de eliminar?",
      text: "Un proveedor eliminado no se puede recuperar!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        // consulta a la api para eliminar
        deleteByIdProveedor(id)
          .then((res) => {
            Swal.fire("Eliminado!", "El proveedor fue eliminado.", "success");
            setIsLoad(true);
          })
          .catch((error) => {
            Swal.fire({
              type: "error",
              title: "Error",
              text: "Hubo un error vuelve a intentar!",
            });
            console.log(error.response);
          });
      }
    });
  };

  return (
    <tr>
      <th scope="row">{proveedor.id}</th>
      <td>{proveedor.nombre}</td>
      <td>{proveedor.telefono}</td>
      <td>{proveedor.direccion}</td>
      <td>{proveedor.url}</td>
      <td>
        <button
          className="btn text-danger"
          onClick={() => {
            history.push(`/edit-proveedor/${proveedor.id}`, {
              proveedor: proveedor,
            });
          }}
        >
          <FontAwesomeIcon icon={faEdit} /> Editar
        </button>
        <button
          className="btn text-primary"
          onClick={() => btnEliminarProveedor(proveedor.id)}
        >
          <FontAwesomeIcon icon={faTrash} /> Eliminar
        </button>
      </td>
    </tr>
  );
};

export default withRouter(ItemProveedor);
