import axios from 'axios';
import {url} from './global';

export const getAllListasPrecios = async (activo, page, nombre, size = 50) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    let res = await axios.get(`${url}/v2/lista-precios?${nombre?`nombre=${nombre}&`:""}activo=${activo}&page=${page}&size=${size}`,{headers});
    return res;
}

export const getByIdListasPrecios = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.get(`${url}/v2/lista-precios/${id}`,{headers});
    return res;
}

export const editByIdListasPrecios = async (listaPrecio) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.put(`${url}/v2/lista-precios/${listaPrecio.id}`, listaPrecio,{headers});
    return res;
}

export const deleteByIdListasPrecios = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.delete(`${url}/v2/lista-precios/${id}`,{headers});
    return res;
}

export const saveListasPrecios = async (listaPrecio) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type" : "application/json",
        "Authorization" : "Bearer ".concat(token)
    }
    const res = await axios.post(`${url}/v2/lista-precios`,listaPrecio,{headers});
    return res;
}

export const recoverListaPrecioDeleted = async (id) => {
    let token = (sessionStorage.getItem('token'));
    let headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer ".concat(token)
    }
    let res = await axios.put(`${url}/v2/lista-precios/${id}/recover`, {}, { headers });
    return res;
}
